import { XIcon } from '@heroicons/react/solid';
import Joi from 'joi';
import { defaults, merge, some } from 'lodash';
import { Button } from '../components/Button';
import { TextArea } from '../components/forms/TextArea';
import { TextField } from '../components/forms/TextField';
import { makeForm } from '../hooks/use-form'

const useForm = makeForm({
  id: Joi.number().optional(),
  text: Joi.string().required(),
  order: Joi.number().min(0).optional().default(0),
});

/**
 *
 */
interface AnswerFormData {
  readonly text: string;
  readonly correct: boolean;
  readonly order?: number;
}

/**
 *
 */
export interface NewQuizQuestionFormData {
  readonly text: string;
  readonly order?: string;
  readonly id?: number;
}

/**
 *
 */
export interface NewQuizQuestionFormProps {
  readonly initialData?: Partial<NewQuizQuestionFormData>;

  readonly submitting?: boolean;

  readonly update?: boolean;

  /**
   *
   */
  readonly onSubmit: (data: NewQuizQuestionFormData) => void;

  /**
   *
   */
  readonly onDelete?: (data: NewQuizQuestionFormData) => void;

  /**
   *
   */
  readonly onCancel: () => void;
}

/**
 *
 * @param props
 */
export const NewQuizQuestionForm: React.FC<NewQuizQuestionFormProps> = props => {
  const form = useForm(merge({
    text: '',
    order: '0',
  }, props.initialData));

  console.log(form.errors);
  return <form>
    <div className="mb-5">
      <h3 className="text-lg leading-6 font-medium text-gray-900">New Quiz Question</h3>
      <p className="mt-1 text-sm text-gray-500">
        Add a new question to your quiz.
      </p>
    </div>

    <TextArea
      name="question"
      className="mb-5"
      required
      label="Question"
      defaultValue={props.initialData?.text}
      error={form.isTouched('text')
        ? form.errorMessage('text')
        : undefined
      }
      onInput={form.update.text}
    />

    <TextField
      name="order"
      className="mb-5"
      type="text"
      label="Order"
      defaultValue={props.initialData?.order === '0' ? undefined : props.initialData?.order}
      error={form.isTouched('order')
        ? form.errorMessage('order')
        : undefined
      }
      onInput={form.update.order}
    />

    <div className="flex justify-end items-center">
      <Button
        type="button"
        kind="secondary"
        className="mr-2"
        onClick={e => props.onCancel()}
        disabled={props.submitting}
      >
        <XIcon className="w-4 h-4 mr-2" />
        Cancel
      </Button>

      <Button
        type="button"
        kind="primary"
        disabled={some(form.errors) || props.submitting}
        onClick={() => props.onSubmit(form.formData as NewQuizQuestionFormData)}
      >
        {props.update ? 'Update' : 'Create' }
      </Button>
    </div>
  </form>;
};
