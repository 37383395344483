import { merge } from 'lodash';
import { useContext } from 'react';
import { AppContext } from '../App';
import { useFetcher } from './use-fetcher';
import { SuspenseResult, useSuspense } from './use-suspense';

const defaultRequestInit = {};

const defaultDeps = [] as unknown[];

/**
 *
 * @param path
 * @param request
 */
export function useApi(path: null, request?: RequestInit, deps?: unknown[]): SuspenseResult<null>;

/**
 *
 * @param path
 * @param request
 */
export function useApi<T>(path: string, request?: RequestInit, deps?: unknown[]): SuspenseResult<T>;

/**
 *
 * @param path
 * @param request
 */
 export function useApi<T>(path: string | null, request?: RequestInit, deps?: unknown[]): SuspenseResult<T>;

/**
 *
 * @param path
 * @returns
 */
export function useApi<T>(
  path: string | null,
  request: RequestInit = defaultRequestInit,
  deps: unknown[] = defaultDeps
) {
  const { fetcher } = useFetcher(undefined, request);
  const { accessToken } = useContext(AppContext);

  return useSuspense<T>(accessToken || request.credentials === 'omit' ? path : null, async () => {
    console.log('Fetching ' + path);
    const response = await fetcher(path || undefined);

    return response.json();
  }, [request, fetcher, ...deps]);
}
