import { AppState, Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'use-memo-one';

/**
 *
 * @param param0
 * @returns
 *
 * @see https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#1-protecting-a-route-in-a-react-router-dom-app
 */
export const RoutableAuth0Provider: React.FC<PropsWithChildren<Auth0ProviderOptions>> = ({ children, ...props }) => {
  const navigate = useNavigate();

  /**
   *
   */
  const onRedirect = useCallback((appState?: AppState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  }, [navigate]);

  return <Auth0Provider onRedirectCallback={onRedirect} {...props}>
    {children}
  </Auth0Provider>;
};
