/* This example requires Tailwind CSS v2.0+ */
import classNames from 'classnames';
import { ElementType, PropsWithChildren, ReactNode } from 'react';

/**
 *
 */
export interface ListItemProps {
  readonly id: string | number;
  readonly title: string;
  readonly className?: string;

  readonly attributes?: readonly {
    icon: ElementType,
    title: ReactNode,
  }[];

  readonly rightAttributes?: readonly {
    icon: ElementType,
    title: ReactNode,
  }[];

  readonly labels?: readonly {
    color: string;
    text: string;
  }[];

  /**
   *
   */
  readonly onClick?: () => void;
}

/**
 *
 */
export interface ListProps {
  readonly items?: readonly ListItemProps[];

  readonly title?: ReactNode;

  readonly description?: ReactNode;

  readonly className?: string;

  readonly renderActions?: () => ReactNode;

  readonly renderDetails?: () => ReactNode;
}

/**
 *
 * @param props
 * @returns
 */
export const List: React.FC<PropsWithChildren<ListProps>> = props =>
  <div className={classNames("bg-white shadow overflow-hidden sm:rounded-md", props.className)}>
    {/* Heading */}
    {props.title &&
      <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <div className="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
          <div className="ml-4 mt-2">
            <h3 className="text-lg leading-6 font-medium text-gray-900">{props.title}</h3>

            {/* Description */}
            {props.description &&
              <p className="mt-1 text-sm text-gray-500">
                {props.description}
              </p>
            }
          </div>
          <div className="ml-4 mt-2 flex-shrink-0">
            {props.renderActions?.()}
          </div>
        </div>
      </div>
    }

    {/* List */}
    <ul className="divide-y divide-gray-200">
      {props.children}
      {props.items?.map(listItem => (
        <ListItem key={listItem.id} {...listItem} />
      ))}
    </ul>
  </div>;

/**
 *
 * @param props
 * @returns
 */
export const ListItem: React.FC<ListItemProps> = props =>
  <li key={props.id} className={props.className}>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <div onClick={props.onClick} className={classNames("block", {"hover:bg-gray-50 cursor-pointer": props.onClick})}>
      <div className="px-4 py-4 sm:px-6">
        <div className="flex items-center justify-between">
          <p className={classNames("text-sm font-medium truncate", {"text-indigo-600": props.onClick})}>{props.title}</p>
          <div className="ml-2 flex-shrink-0 flex">
            {props.labels?.map((label, i) =>
              <p key={i} className={classNames('px-2 inline-flex text-xs leading-5 font-semibold rounded-full', label.color)}>
                {label.text}
              </p>
            )}
          </div>
        </div>
        <div className="mt-2 sm:flex sm:justify-between">
          <div className="sm:flex">
            {props.attributes?.map((attribute, i) =>
              <p key={i} className="flex items-center text-sm text-gray-500 sm:ml-6 first:sm:ml-0">
                <attribute.icon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                {attribute.title}
              </p>
            )}
            {/* <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
              <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              {position.location}
            </p> */}
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
            {props.rightAttributes?.map((attribute, i) =>
              <p key={i} className="flex items-center text-sm text-gray-500">
                <attribute.icon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                {attribute.title}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  </li>;
