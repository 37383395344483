import { useHref, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useApi } from '../hooks/use-api';
import { Serialized } from '../serialized';
import { QuizWithQuestionsAndAnswers } from 'quiz-builder-api/src/lib/models/quiz-with-questions-and-answers';
import { PageHeading } from '../components/PageHeading';
import { List, ListItem } from '../components/List';
import { Button } from '../components/Button';
import { BadgeCheckIcon, CheckIcon, ExclamationIcon, LightBulbIcon, LinkIcon, PencilIcon, PlusIcon, SaveIcon, TrashIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { useCallback, useContext, useState } from 'react';
import { useFetcher } from '../hooks/use-fetcher';
import { AppContext } from '../App';
import { CheckCircleIcon, XIcon } from '@heroicons/react/outline';
import { Modal, ModalProps } from '../components/Modal';
import { NewQuizQuestionFormProps, NewQuizQuestionForm, NewQuizQuestionFormData } from '../forms/NewQuizQuestionForm';
import { QuizQuestion } from 'quiz-builder-api/src/lib/models/types';
import { TextField } from '../components/forms/TextField';

const defaultRequestInit = {};

/**
 *
 * @param props
 */
export const Quiz: React.FC = props => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { alert, prompt, appUser } = useContext(AppContext);

  const [ editing, setEditing ] = useState(false);
  const [ creatingQuestion, setCreatingQuestion ] = useState(false);
  const [ showNewQuizQuestionModal, setShowNewQuizQuestionModal ] = useState(false);
  const [ showPermalinkModal, setShowPermalinkModal ] = useState(false);
  const [ titleInputEl, setTitleInputEl ] = useState<HTMLInputElement | null>(null);

  const { value: quiz, refetch } = useApi<Serialized<QuizWithQuestionsAndAnswers>>(
    `/quizzes/${params.quizId}?include[]=questions.answers&t=${location.state || ''}`
  , defaultRequestInit);

  const { fetcher: putQuiz } = useFetcher(`/quizzes/${params.quizId}`, {
    method: 'put'
  });

  const { fetcher: destroyQuiz } = useFetcher(`/quizzes/${params.quizId}`, {
    method: 'delete'
  });

  const { fetcher: postQuestion } = useFetcher(`/quizzes/${params.quizId}/questions`, {
    method: 'post'
  });

  const quizPublicHref = `${window.location.origin}${useHref(`/quizzes/${quiz.uuid}`)}`;


  /**
   *
   */
  const updateQuizTitle = useCallback(async () => {
    const title =  titleInputEl ? titleInputEl.value : undefined

    const response = await putQuiz({
      body: JSON.stringify({
        title: title
      }),
    });

    if (response.ok) {
      alert({
        title: 'Successfully updated!',
        icon: <CheckCircleIcon className="text-green-400" />,
        message: 'Your quiz was successfully updated.'
      });
    } else {
      alert({
        title: 'Failed to update!',
        icon: <ExclamationIcon className="text-red-400"  />,
        message: 'Your quiz was failed to update.'
      });
    }

    refetch();
  }, [alert, putQuiz, refetch, titleInputEl]);

  /**
   *
   */
  const publishQuiz = useCallback(async (revert: boolean = false) => {
    let proceed = await prompt({
      title: `Publishing "${quiz.title}"`,
      message: <>
        You are about to publish your quiz "{quiz.title}," which means that
        <ul className="ml-7 my-5 bullet list-disc">
          <li>You will no longer be able to make updates to the questions/answers;</li>
          <li>You will be able to share a public permalink to this quiz for others to take your quiz;</li>
          <li>You will still be able to delete the quiz at any time;</li>
          <li><em>You cannot undo this operation.</em></li>
        </ul>
        Are you sure you want to publish?
      </>
    });

    if (!proceed) return;

    const response = await putQuiz(`/publish?revert=${revert}`);

    if (response.ok) {
      alert({
        title: 'Successfully published!',
        icon: <CheckCircleIcon className="text-green-400" />,
        message: 'Your quiz was successfully published!'
      });
    } else {
      alert({
        title: 'Failed to publish!',
        icon: <ExclamationIcon className="text-red-400"  />,
        message: 'Your quiz failed to publish.'
      });
    }

    refetch();
  }, [alert, prompt, putQuiz, quiz.title, refetch]);

  /**
   *
   */
  const deleteQuiz = useCallback(async () => {
    const proceed = await prompt({
      title: `Deleting "${quiz.title}"`,
      message: 'Are you sure you want to delete this quiz? This action is permanent.',
      dangerous: true,
    });

    if (!proceed) return;

    const response = await destroyQuiz();

    if (response.ok) {
      navigate('/dashboard/quizzes', { state: Date.now() });

      alert({
        title: 'Successfully deleted!',
        icon: <CheckCircleIcon className="text-green-400" />,
        message: 'Your quiz was successfully deleted.'
      });
    } else {
      alert({
        title: 'Failed to delete!',
        icon: <ExclamationIcon className="text-red-400"  />,
        message: 'Your quiz was was not deleted.'
      });
    }
  }, [alert, destroyQuiz, navigate, prompt, quiz.title]);

  /**
   *
   */
  const createQuestion = useCallback(async (data: NewQuizQuestionFormData) => {
    setCreatingQuestion(true);

    const response = await postQuestion({
      body: JSON.stringify(data)
    });

    if (response.ok) {
      alert({
        title: 'Successfully created!',
        icon: <CheckCircleIcon className="text-green-400" />,
        message: 'Your new question was successfully added to the quiz!'
      });

      const question = await response.json() as QuizQuestion;

      navigate(`/dashboard/quizzes/${params.quizId}/questions/${question.id}`);
    } else {
      alert({
        title: 'Failed to create!',
        icon: <ExclamationIcon className="text-red-400"  />,
        message: 'Your question was not added to the quiz.'
      });
    }

    refetch();
  }, [alert, navigate, params.quizId, postQuestion, refetch]);

  return <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-10">
    <div className="max-w-full mx-auto">
      <PageHeading
        breadcrumbs={[
          {title: 'Quizzes', href: '/dashboard/quizzes'},
          {title: quiz.title, href: '#'},
        ]}
        renderActions={() => <>
          <Button kind="danger" className="mr-2" onClick={deleteQuiz}>
            <TrashIcon className="w-4 mr-2" />
            Delete
          </Button>

          {quiz.publishedAt
            ? <>
                <Button kind="secondary" className="mr-2" onClick={() => setShowPermalinkModal(true)}>
                  <LinkIcon className="w-4 mr-2" />
                  Get Permalink
                </Button>
                <Button kind="success" disabled onClick={() => publishQuiz(true)}>
                  <BadgeCheckIcon className="w-4 mr-2" />
                  Published
                </Button>
              </>
            : <Button kind="success" onClick={() => publishQuiz()}>
                <CheckIcon className="w-4 mr-2" />
                Publish
              </Button>
            }
        </>}
      >
        <div className="flex items-center group" onClick={() => setEditing(true)}>
          {editing
            ? <>
                <input
                  ref={setTitleInputEl}
                  type="text"
                  defaultValue={quiz.title}
                  className="my-2 ml-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md read-only:bg-gray-200 read-only:text-gray-400"
                />
                <Button
                  size="sm"
                  className="ml-2 h-full text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  onClick={e => { e.stopPropagation(); updateQuizTitle(); }}
                >
                  <SaveIcon className="w-4 h-4 mr-2" />
                  Save
                </Button>

                <Button
                  size="sm"
                  className="ml-2 h-full text-sm font-medium rounded-md text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
                  onClick={e => { e.stopPropagation(); setEditing(false); }}
                >
                  <XIcon className="w-4 h-4 mr-2" />
                  Cancel
                </Button>
              </>
            : <span className="hover:cursor-pointer">
                {quiz.title}
              </span>
          }
          <PencilIcon className={classNames("invisible w-5 h-5 ml-2 text-gray-400", {"group-hover:visible": !editing})} />
        </div>
      </PageHeading>
      <List title="Questions" description={`${quiz.questions.length} total question(s).`} renderActions={() =>
        <Button
          kind="primary"
          onClick={() => setShowNewQuizQuestionModal(true)}
          disabled={!!quiz.publishedAt}
        >
          <PlusIcon className="w-4 mr-2" />
          New Question
        </Button>
      }>
        {quiz.questions?.map((question, i) =>
          <ListItem
            id={question.id}
            title={`${i + 1}. ${question?.text}`}
            onClick={() => navigate(`/dashboard/quizzes/${quiz.id}/questions/${question.id}`)}
            attributes={[{
              title: `${question.answers.length} answers`,
              icon: LightBulbIcon
            }]}
          />
        )}
      </List>
    </div>

    <NewQuizQuestionFormModal
      open={showNewQuizQuestionModal}
      submitting={creatingQuestion}
      onClose={() => setShowNewQuizQuestionModal(false)}
      onSubmit={createQuestion}
      onCancel={() => setShowNewQuizQuestionModal(false)}
    />

    <Modal open={showPermalinkModal} onClose={() => setShowPermalinkModal(false)}>
      <h3 className="text-lg mb-5">Permalink</h3>
      <p className="mb-5">Copy the following permalink to share your quiz with others.</p>
      <TextField
        type="text"
        required
        readOnly
        defaultValue={quizPublicHref}
        onClick={e => e.currentTarget.setSelectionRange(0, e.currentTarget.value.length)}
      />
    </Modal>
  </div>;
};

/**
 *
 */
export const NewQuizQuestionFormModal: React.FC<NewQuizQuestionFormProps & ModalProps> = props =>
  <Modal {...props}>
    <NewQuizQuestionForm {...props} />
  </Modal>;
