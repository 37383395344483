import classNames from 'classnames';
import { merge } from 'lodash';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { QuizWithQuestionsAndPartialAnswers } from 'quiz-builder-api/src/lib/models/quiz-with-questions-and-partial-answers';
import { Quiz } from 'quiz-builder-api/src/lib/models/types';
import { Button } from '../components/Button';
import { Card } from '../components/Card';
import { useApi } from '../hooks/use-api';
import { useFetcher } from '../hooks/use-fetcher';
import { Serialized } from '../serialized';

export type AnswersToQuestions = Record<number, { id: number }[]>;

/**
 *
 */
export interface Results {
  readonly correctCount: number;
  readonly total: number;
}

const defaultRequestInit = {
  credentials: 'omit',
} as Partial<RequestInit>;

/**
 *
 * @param props
 */
export const PublicQuiz: React.FC = props => {
  const params = useParams();
  const { value: quiz } = useApi<Serialized<QuizWithQuestionsAndPartialAnswers>>(
    `/quizzes/published/${params.uuid}?include[]=questions`,
    defaultRequestInit
  );

  const [ currentIndex, setCurrentIndex ] = useState(0);
  const [ answers, setAnswers ] = useState<Set<number>>(new Set());
  const [ allAnswers, setAllAnswers ] = useState<Record<number, number[]>>({});
  const [ results, setResults ] = useState<Results | null>(null);
  const [ submitting, setSubmitting ] = useState(false);

  const { fetcher: postResults } = useFetcher(`/quizzes/published/${quiz.uuid}`, {
    method: 'post',
    credentials: 'omit',
  })

  const currentQuestion = quiz.questions[currentIndex];

  /**
   *
   */
  const answerQuestion = useCallback((answer: {id: number}) => {
    if (answers.has(answer.id)) {
      answers.delete(answer.id);
    } else {
      answers.add(answer.id);
    }

    setAnswers(new Set([...answers]));
  }, [answers]);

  console.log(allAnswers);

  /**
   *
   */
  const isSelected = useCallback((answer: {id: number}) =>
    answers.has(answer.id)
  , [answers]);

  /**
   *
   */
  const nextQuestion = useCallback(() => {
    setAllAnswers({
      ...allAnswers,
      [currentQuestion.id]: [...answers],
    });

    setAnswers(new Set());
    setCurrentIndex(index => index + 1);
  }, [allAnswers, currentQuestion.id, answers]);

  /**
   *
   */
  const previousQuestion = useCallback(() => {
    setAnswers(new Set(allAnswers[quiz.questions[currentIndex - 1].id]));
    setCurrentIndex(index => index - 1);
  }, [allAnswers, currentIndex, quiz.questions]);

  /**
   *
   */
  const submit = useCallback(async () => {
    setSubmitting(true);

    const response = await postResults({
      body: JSON.stringify({
        answers: {
          ...allAnswers,
          [currentQuestion.id]: [...answers],
        },
      })
    });

    if (!response.ok) {

    }

    const results = await response.json() as Results;
    setResults(results);
    setSubmitting(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAnswers, postResults]);

  return submitting
    ? <Card className="sm:mx-10 sm:w-full md:mx-auto md:w-6/12 mt-10">
        <h3 className="text-xl text-center">Submitting...</h3>
      </Card>
    : results
    ? <Card className="sm:mx-10 sm:w-full md:mx-auto md:w-6/12 mt-10">
        <h1 className="text-lg">Results</h1>
        <p className="text-center font-bold">
          <span className="text-green-600">{results.correctCount}</span> / <span>{results.total}</span> correct
        </p>
      </Card>
    : <Card className="sm:mx-10 sm:w-full md:mx-auto md:w-6/12 mt-10">
        <h1 className="text-3xl text-indigo-700">{quiz.title}</h1>
        <p className="text-gray-400 mb-5">{quiz.questions.length} question(s)</p>

        <h4 className="font-semibold">Question {currentIndex + 1} / {quiz.questions.length}</h4>
        <h3 className="text-indigo-900">
          {currentQuestion.text}
        </h3>
        <p className="text-gray-400 text-sm mt-2 mb-5">Select all applicable answers</p>

        {currentQuestion.answers.map((answer, i) =>
          <Card
            className={classNames("mb-5 cursor-pointer select-none border-2", {"border-orange-700 border-solid border-2 text-orange-700": isSelected(answer)})}
            onClick={() => answerQuestion(answer)}
          >
            {i + 1}. {answer.text}
          </Card>
        )}

        <div className="mt-5 flex justify-between">
          <Button kind="secondary" onClick={previousQuestion} disabled={currentIndex === 0}>Back</Button>
          {currentIndex === quiz.questions.length - 1
            ? <Button kind="success" disabled={answers.size === 0} onClick={submit}>Finish</Button>
            : <Button kind="primary" onClick={nextQuestion} disabled={answers.size === 0}>Next</Button>
          }
        </div>
      </Card>;
};
