/* This example requires Tailwind CSS v2.0+ */
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';
import { Link } from 'react-router-dom';

/**
 *
 */
export type Breadcrumb = Readonly<{
  title: string;
  href: string;
  state?: unknown;
}>;

/**
 *
 */
export interface PageHeadingProps {
  readonly breadcrumbs?: readonly Breadcrumb[];

  readonly backHref?: string;

  readonly className?: string;

  /**
   *
   */
  readonly renderActions?: () => ReactNode;
}


/**
 *
 * @param props
 * @returns
 */
export const PageHeading: React.FC<PropsWithChildren<PageHeadingProps>> = props => {
  return (
    <div className={classNames("mb-10", props.className)}>
      <div>
        <nav className="sm:hidden" aria-label="Back">
          <Link to={props.backHref || '/'} className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700">
            <ChevronLeftIcon className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
            Back
          </Link>
        </nav>
        <nav className="hidden sm:flex" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-4">
            {props.breadcrumbs?.map((crumb, i) =>
              <li key={crumb.title}>
                <div className={classNames('flex', {'items-center': i !== 0})}>
                  {i !== 0 && <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />}

                  <Link
                    to={crumb.href}
                    state={crumb.state}
                    className={classNames("text-sm font-medium text-gray-500 hover:text-gray-700", {'ml-4': i !== 0})}
                  >
                    {crumb.title}
                  </Link>
                </div>
              </li>
            )}
          </ol>
        </nav>
      </div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{props.children}</h2>
        </div>
        <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
          {props.renderActions?.()}
        </div>
      </div>
    </div>
  )
}
