import { useContext } from 'react';
import { AppContext } from '../App';

/**
 *
 * @param props
 */
export const Home: React.FC = props => {
  const { user } = useContext(AppContext);

  return <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-10">
    <div className="max-w-full mx-auto">
      <h1 className="text-4xl mb-10">Welcome, {user?.email}</h1>
      Loaded
    </div>
  </div>;
};
