import { PropsWithChildren, ReactNode } from 'react';
import { Button } from './Button';
import { Modal } from './Modal';

export interface PromptProps {
  readonly title: ReactNode;
  readonly show: boolean;
  readonly message?: ReactNode;
  readonly yesText?: string;
  readonly noText?: string | false;
  readonly dangerous?: boolean;

  /**
   *
   */
  readonly onResponse: (result: boolean) => void;
}

/**
 *
 * @param props
 */
export const Prompt: React.FC<PropsWithChildren<PromptProps>> = props => {
  return <Modal open={props.show} onClose={() => props.onResponse(false)}>
    <h3 className="text-lg mb-5">{props.title}</h3>
    <div className="mb-5">
      {props.message}
      {props.children}
    </div>
    <div className="flex justify-end">
      {props.noText !== false &&
        <Button
          kind="secondary"
          className="mr-2"
          onClick={() => props.onResponse(false)}
        >
          {props.noText || 'Cancel'}
        </Button>
      }
      <Button
        kind={props.dangerous ? 'danger' : 'primary'}
        onClick={() => props.onResponse(true)}
      >
        {props.yesText || 'Continue'}
      </Button>
    </div>
  </Modal>;
};
