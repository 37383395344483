import { useNavigate, useParams } from 'react-router-dom';
import { useApi } from '../hooks/use-api';
import { Serialized } from '../serialized';
import { QuizQuestionWithAnswers } from 'quiz-builder-api/src/lib/models/quiz-question-with-answers';
import { PageHeading } from '../components/PageHeading';
import { List, ListItem } from '../components/List';
import { Button } from '../components/Button';
import { CheckCircleIcon, ExclamationIcon, PencilIcon, PlusIcon, TrashIcon } from '@heroicons/react/solid';
import { QuizWithQuestions } from 'quiz-builder-api/src/lib/models/quiz-with-questions';
import { NewQuizAnswerForm, NewQuizAnswerFormData, NewQuizAnswerFormProps } from '../forms/NewQuizAnswerForm';
import { Modal, ModalProps } from '../components/Modal';
import { useContext, useState } from 'react';
import { useFetcher } from '../hooks/use-fetcher';
import { useCallback } from 'use-memo-one';
import { AppContext } from '../App';
import { NewQuizQuestionFormModal } from './Quiz';
import { NewQuizQuestionFormData } from '../forms/NewQuizQuestionForm';
import { QuizAnswer } from 'quiz-builder-api/src/lib/models/types';
import { pick } from 'lodash';

/**
 *
 * @param props
 */
export const Question: React.FC = props => {
  const [ showNewQuizQuestionForm, setShowNewQuizQuestionForm ] = useState(false);
  const [ showNewQuizAnswerForm, setShowNewQuizAnswerForm ] = useState(false);
  const [ selectedAnswer, setSelectedAnswer ] = useState<Serialized<QuizAnswer> | null>(null);
  const [ creating, setCreating ] = useState(false);
  const [ updating, setUpdating ] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const { alert, prompt } = useContext(AppContext);

  const { value: quiz } = useApi<Serialized<QuizWithQuestions>>(
    `/quizzes/${params.quizId}?include[]=questions.answers`
  );

  const { value: question, refetch } = useApi<Serialized<QuizQuestionWithAnswers>>(
    `/quizzes/${params.quizId}/questions/${params.questionId}?include[]=answers`
  );

  const { fetcher: putQuestion } = useFetcher(`/quizzes/${params.quizId}/questions/${params.questionId}`, {
    method: 'put',
  });

  const { fetcher: deleteQuestion } = useFetcher(`/quizzes/${params.quizId}/questions/${params.questionId}`, {
    method: 'delete',
  });

  /**
   *
   */
  const destroyQuestion = useCallback(async () => {
    const proceed = await prompt({
      title: 'Delete question',
      message: 'Are you sure you want to delete this question?',
      dangerous: true,
    });

    if (!proceed) return;

    const response = await deleteQuestion();

    if (response.ok) {
      alert({
        title: 'Successfully deleted!',
        icon: <CheckCircleIcon className="text-green-400" />,
        message: 'Your question was successfully created.'
      });

      navigate(`/dashboard/quizzes/${params.quizId}`, {
        state: Date.now(),
      });
    } else {
      alert({
        title: 'Failed to delete!',
        icon: <ExclamationIcon className="text-red-400"  />,
        message: 'Your question was not able to be deleted.'
      });
    }
  }, [alert, deleteQuestion, navigate, params.quizId, prompt]);

  /**
   *
   */
  const createAnswer = useCallback(async (data: NewQuizAnswerFormData) => {
    setCreating(true);

    const response = await putQuestion({
      body: JSON.stringify({
        answers: [data]
      }),
    });

    if (response.ok) {
      alert({
        title: 'Successfully created!',
        icon: <CheckCircleIcon className="text-green-400" />,
        message: 'Your answer was successfully created.'
      });
    } else {
      alert({
        title: 'Failed to create!',
        icon: <ExclamationIcon className="text-red-400"  />,
        message: 'Your answer failed to create.'
      });
    }

    refetch();
  }, [alert, putQuestion, refetch]);

  /**
   *
   */
  const updateQuestion = useCallback(async (data: NewQuizQuestionFormData) => {
    setUpdating(true);

    const response = await putQuestion({
      body: JSON.stringify(data),
    });

    if (response.ok) {
      alert({
        title: 'Successfully updated!',
        icon: <CheckCircleIcon className="text-green-400" />,
        message: 'Your question was successfully updated.'
      });
    } else {
      alert({
        title: 'Failed to update!',
        icon: <ExclamationIcon className="text-red-400"  />,
        message: 'Your question was not able to be updated.'
      });
    }

    refetch();
  }, [alert, putQuestion, refetch]);

  /**
   *
   */
  const deleteSelectedAnswer = useCallback(async () => {
    const proceed = await prompt({
      title: 'Delete answer?',
      message: 'Are you sure you want to delete this answer?',
      dangerous: true,
    });

    if (!proceed) return;

    setSelectedAnswer(null);

    const response = await putQuestion({
      body: JSON.stringify({
        answers: [{ ...pick(selectedAnswer, 'id'), $delete: true }]
      }),
    });

    if (response.ok) {
      alert({
        title: 'Successfully deleted!',
        icon: <CheckCircleIcon className="text-green-400" />,
        message: 'Your answer was successfully deleted.'
      });

    } else {
      alert({
        title: 'Failed to delete!',
        icon: <ExclamationIcon className="text-red-400"  />,
        message: 'Your answer was not able to be deleted.'
      });
    }

    refetch();
  }, [alert, prompt, putQuestion, refetch, selectedAnswer]);

  /**
   *
   */
   const updateSelectedAnswer = useCallback(async (data: NewQuizAnswerFormData) => {
    setSelectedAnswer(null);
    setUpdating(true);

    const response = await putQuestion({
      body: JSON.stringify({
        answers: [{ ...pick(selectedAnswer, 'id'), ...data }]
      }),
    });

    if (response.ok) {
      alert({
        title: 'Successfully updated!',
        icon: <CheckCircleIcon className="text-green-400" />,
        message: 'Your answer was successfully updated.'
      });

    } else {
      alert({
        title: 'Failed to updated!',
        icon: <ExclamationIcon className="text-red-400"  />,
        message: 'Your answer was not able to be updated.'
      });
    }

    refetch();
  }, [alert, putQuestion, refetch, selectedAnswer]);

  return <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-10">
    <div className="max-w-full mx-auto">
      <PageHeading
        breadcrumbs={[
          {title: 'Quizzes', href: '/dashboard/quizzes'},
          {title: quiz.title, href: `/dashboard/quizzes/${quiz.id}`, state: 'reload'},
          {title: question.text, href: '#'},
        ]}

        renderActions={() => <>
            <Button kind="danger" className="mr-2" onClick={destroyQuestion}>
              <TrashIcon className="w-4 mr-2" />
              Delete
            </Button>
            <Button kind="primary" onClick={() => setShowNewQuizQuestionForm(true)}>
              <PencilIcon className="w-4 mr-2" />
              Edit
            </Button>
          </>
        }
      >
        {question.text}
      </PageHeading>

      <List
        title="Answers"
        description={`This question has ${question.answers.length} total answers(s).`}
        renderActions={() =>
          !quiz.publishedAt &&
            <Button kind="primary" onClick={() => setShowNewQuizAnswerForm(true)}>
              <PlusIcon className="w-4 mr-2" />
              New Answer
            </Button>
        }
      >
        {question.answers.map((answer, i) =>
          <ListItem
            key={answer.id}
            id={answer.id}
            title={`${i + 1}. ${answer?.text}`}
            onClick={() => setSelectedAnswer(answer)}
            labels={answer.correct ? [
              {color: 'bg-green-100 text-green-800', text: 'Correct'},
            ] : [
              {color: 'bg-red-100 text-red-800', text: 'Incorrect'},
            ]}
          />
        )}
      </List>
    </div>

    <NewQuizAnswerFormModal
      open={showNewQuizAnswerForm}
      submitting={creating}
      onClose={() => {}}
      onSubmit={createAnswer}
      onCancel={() => setShowNewQuizAnswerForm(false)}
    />

    <NewQuizQuestionFormModal
      initialData={{
        text: question.text,
        order: question.order.toString(),
      }}
      update
      open={showNewQuizQuestionForm}
      submitting={updating}
      onClose={() => setShowNewQuizQuestionForm(false)}
      onSubmit={updateQuestion}
      onCancel={() => setShowNewQuizQuestionForm(false)}
    />

    {selectedAnswer && <NewQuizAnswerFormModal
      initialData={{
        id: selectedAnswer.id,
        text: selectedAnswer.text,
        correct: selectedAnswer.correct,
        order: selectedAnswer.order.toString(),
      }}
      open
      updating
      onClose={() => setSelectedAnswer(null)}
      onCancel={() => setSelectedAnswer(null)}
      onSubmit={updateSelectedAnswer}
      onDelete={deleteSelectedAnswer}
    />}
  </div>;
};

/**
 *
 * @param props
 * @returns
 */
export const NewQuizAnswerFormModal: React.FC<ModalProps & NewQuizAnswerFormProps> = props =>
  <Modal {...props}>
    <NewQuizAnswerForm {...props} />
  </Modal>;
