import classNames from 'classnames';
import { omit } from 'lodash';
import { PropsWithChildren } from 'react';

/**
 *
 */
export interface CardProps {
  readonly well?: boolean;

  readonly className?: string;

  readonly [key: string]: unknown;
}

/**
 *
 * @param props
 * @returns
 */
export const Card: React.FC<PropsWithChildren<CardProps>> = props => {
  const restProps = omit(props, 'className', 'well', 'children');

  return props.well
    ? <div className={classNames("bg-slate-200 overflow-hidden rounded-lg", props.className)} {...restProps}>
        <div className="px-4 py-5 sm:p-6">{props.children}</div>
      </div>
    : <div className={classNames("bg-white overflow-hidden shadow rounded-lg", props.className)} {...restProps}>
        <div className="px-4 py-5 sm:p-6">{props.children}</div>
      </div>;
};
