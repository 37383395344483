/* This example requires Tailwind CSS v2.0+ */
import { useCallback, useState } from 'react'
import { Switch } from '@headlessui/react'
import classNames from 'classnames'

/**
 *
 */
export type ToggleProps = ({
  readonly label: undefined;
  readonly subLabel: never;
} | {
  readonly label: string;
  readonly subLabel?: string;
}) & {
  readonly className?: string;

  readonly defaultValue?: boolean;

  /**
   *
   */
  readonly onChange: (enabled: boolean) => void;
};

/**
 *
 * @param props
 * @returns
 */
export const Toggle: React.FC<ToggleProps> = props => {
  const [enabled, setEnabled] = useState(props.defaultValue || false)

  /**
   *
   */
  const change = useCallback((enabled: boolean) => {
    setEnabled(enabled);
    props.onChange(enabled);
  }, [props]);

  return (
    <Switch.Group as="div" className={classNames("flex items-center", props.className)}>
      <Switch
        checked={enabled}
        onChange={change}
        className={classNames(
          enabled ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
          )}
        />
      </Switch>
      {typeof props.label === 'string' &&
        <Switch.Label as="span" className="ml-3">
          <span className="text-sm font-medium text-gray-900">{props.label}</span>
          <span className="text-sm text-gray-500">{props.subLabel}</span>
        </Switch.Label>
      }
    </Switch.Group>
  )
}
