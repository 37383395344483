import Joi, { SchemaLike, ValidationError, ValidationErrorItem, ValidationOptions } from 'joi';
import { assign, defaults, get, mapValues, reduce, set } from 'lodash';
import { FormEvent, useCallback, useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { useMemo } from 'use-memo-one';

/**
 *
 */
type IsNeverType<T> = [T] extends [never] ? true : never;

/**
 *
 */
type PathOf<T extends object, Separator extends string = ''> =
  T extends Array<infer I>
    ? (
      true extends IsNeverType<I>
        ? `[${number}]`
        : (
          I extends object
            ? `[${number}]${PathOf<I, '.'>}`
            : `[${number}]`
        )
    )
    : {
      [K in keyof T]: T[K] extends object
        ? `${Separator}${K & string}${PathOf<T[K], '.'>}`
        : `${Separator}${K & string}`;
    }[keyof T];

/**
 *
 */
type ValueAtPath<T, P extends string> =
  P extends `${infer K}.${infer S}`
    ? K extends keyof T
      ? ValueAtPath<T[K], S>
      : never
    : P extends keyof T
      ? T[P]
      : never;

/**
 *
 */
export type FormErrors<T extends object> = {
  [K in keyof T]?: ValidationErrorItem;
};

/**
 *
 */
export interface Options<
  T extends object
> {
  validationOptions?: ValidationOptions,

  /**
   *
   */
  onSubmit?: (e: FormEvent) => void;
}

/**
 *
 */
export type Updater<V = unknown> = {
  <E extends React.SyntheticEvent<{ value: V }>>(e: E): void;

  directly(value: V): void;
};

/**
 *
 */
export type Updaters<T> = {
  [K in keyof T]-?: Updater<T[K]>;
};

/**
 *
 */
export type ExistingRecord<T> = T & {
  readonly id: string;
};

/**
 *
 */
export const makeForm = (schema: SchemaLike) =>
  <T extends object>(initialData: Partial<T>, options: Options<T> = {}) => {
    const [ touchedFields, setTouchedFields ] = useState(new Set<keyof T>());
    const [ formData, setData ] = useState(initialData);
    const [ errors, setErrors ] = useState({} as FormErrors<T>);

    const compiledSchema = useMemo(() => Joi.compile(schema), []);

    useAsyncEffect(async mounted => {
      try {
        await compiledSchema.validateAsync(
          formData,
          defaults(options.validationOptions, {})
        );

        if (mounted()) setErrors({});
      } catch (e) {
        if (!mounted()) return;

        if (e instanceof ValidationError) {
          setErrors(reduce(e.details, (o, error) =>
            set(o, error.path.join('.'), error)
          , {}) as FormErrors<T>);

          return;
        }

        throw e;
      }
    }, [formData, compiledSchema]);

    /**
     *
     */
    const touch = useCallback((key: keyof T) =>
      setTouchedFields(touchedFields => new Set([...touchedFields, key]))
    , []);

    /**
     *
     */
    const isTouched = useCallback((key: keyof T) =>
      touchedFields.has(key)
    , [touchedFields]);

    /**
     *
     */
    const userErred = useCallback((key: keyof T) =>
      !!errors[key] && isTouched(key)
    , [errors, isTouched]);

    /**
     *
     */
    const errorMessage = useCallback((key: keyof FormErrors<T>) =>
      userErred(key) ? get(errors, key)?.message : undefined
    , [errors, userErred]);

    const update = useMemo(() =>
      mapValues(
        formData,
        (value, key) => {
          /**
           *
           * @param value
           */
          const directly = (value: unknown) => {
            setData(currentData => ({ ...currentData, [key]: value }));
            touch(key as keyof T);
          };

          return assign(
            <E extends React.ChangeEvent<{ value: unknown }>>(e: E) =>
              directly(e.target.value)
            ,
            {
              directly
            }
          );
        }
      ) as Updaters<T>
    , [formData, touch]);

    return useMemo(() => ({
      errors,
      userErred,
      formData,
      update,
      isTouched,
      touch,
      errorMessage
    }), [errors, formData, update, isTouched, touch, userErred, errorMessage]);
  };

