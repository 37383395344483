import { XIcon } from '@heroicons/react/solid';
import Joi from 'joi';
import { defaults, merge, some } from 'lodash';
import { Button } from '../components/Button';
import { TextField } from '../components/forms/TextField';
import { makeForm } from '../hooks/use-form'

const useForm = makeForm({
  id: Joi.number().optional(),
  title: Joi.string().required(),
});

/**
 *
 */
export interface NewQuizFormData {
  readonly title: string;
}

/**
 *
 */
export interface NewQuizFormProps {
  readonly initialData?: Partial<NewQuizFormData>;

  /**
   *
   */
  readonly onSubmit: (data: NewQuizFormData) => void;

  /**
   *
   */
  readonly onCancel: () => void;
}

/**
 *
 * @param props
 */
export const NewQuizForm: React.FC<NewQuizFormProps> = props => {
  const form = useForm(merge({
    title: ''
  }, props.initialData));

  return <form>
    <div className="mb-5">
      <h3 className="text-lg leading-6 font-medium text-gray-900">New Quiz</h3>
      <p className="mt-1 text-sm text-gray-500">
        Create a new quiz.
      </p>
    </div>

    <TextField
      name="Title"
      type="text"
      required
      label="Title"
      error={form.isTouched('title')
        ? form.errorMessage('title')
        : undefined
      }
      onInput={form.update.title}
    />

    <div className="flex justify-end items-center mt-5">
      <Button
        type="button"
        kind="secondary"
        className="mr-2"
        onClick={e => props.onCancel()}
      >
        <XIcon className="w-4 h-4 mr-2" />
        Cancel
      </Button>

      <Button
        type="button"
        kind="primary"
        disabled={some(form.errors)}
        onClick={() => props.onSubmit(form.formData as NewQuizFormData)}
      >
        Create
      </Button>
  </div>
  </form>;
};
