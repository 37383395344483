import { merge } from 'lodash';
import { useContext } from 'react';
import { useCallback, useMemo } from 'use-memo-one';
import { AppContext } from '../App';

const defaultRequestInit = {};

/**
 *
 */
export interface Fetcher {
  /**
   *
   */
  (path?: string, requestInit?: RequestInit): Promise<Response>;

  /**
   *
   */
  (requestInit?: RequestInit): Promise<Response>;

  /**
   *
   */
  (pathOrRequestInit: string | RequestInit, requestInit: RequestInit): Promise<Response>;
}

/**
 *
 * @param pathPrefix
 * @param baseRequestInit
 * @returns
 */
export const useFetcher = (
  pathPrefix?: string,
  baseRequestInit: RequestInit = defaultRequestInit
) => {
  const prefix = useMemo(() =>
    `${process.env.REACT_APP_API_SERVER_URL}${pathPrefix || ''}`
  , [pathPrefix]);

  const { accessToken } = useContext(AppContext);

  const headers = useMemo(() => new Headers({
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
  }), [accessToken]);

  /**
   *
   */
  const fetcher = useCallback<Fetcher>((pathOrRequestInit?: string | RequestInit, requestInit: RequestInit = {}) => {
    let path: string;

    [ path = '', requestInit = {}] = typeof pathOrRequestInit === 'string'
      ? [ pathOrRequestInit, requestInit ]
      : [ undefined, pathOrRequestInit ];

    return fetch(
      `${prefix}${path}`,
      merge({ headers }, baseRequestInit, requestInit)
    );
  }, [prefix, baseRequestInit, headers]);

  return useMemo(() => ({
    fetcher
  }), [fetcher]);
};
