import { XIcon } from '@heroicons/react/solid';
import Joi from 'joi';
import { defaults, merge, some } from 'lodash';
import { Button } from '../components/Button';
import { TextArea } from '../components/forms/TextArea';
import { TextField } from '../components/forms/TextField';
import { Toggle } from '../components/forms/Toggle';
import { makeForm } from '../hooks/use-form'

const useForm = makeForm({
  id: Joi.number().optional(),
  text: Joi.string().required(),
  correct: Joi.boolean().required().default(false),
  order: Joi.number().min(0).optional().default(0),
});

/**
 *
 */
export interface NewQuizAnswerFormData {
  readonly id?: number;
  readonly text: string;
  readonly correct: boolean;
  readonly order?: string;
}

/**
 *
 */
export interface NewQuizAnswerFormProps {
  readonly initialData?: Partial<NewQuizAnswerFormData>;

  readonly submitting?: boolean;

  readonly updating?: boolean;

  /**
   *
   */
  readonly onSubmit: (data: NewQuizAnswerFormData) => void;

  /**
   *
   */
  readonly onDelete?: () => void;

  /**
   *
   */
  readonly onCancel: () => void;
}

/**
 *
 * @param props
 */
export const NewQuizAnswerForm: React.FC<NewQuizAnswerFormProps> = props => {
  const form = useForm(merge({
    text: '',
    correct: false,
    order: '0',
  }, props.initialData));

  console.log(form.errors);

  return <form>
    <div className="mb-5">
      <h3 className="text-lg leading-6 font-medium text-gray-900">New Answer</h3>
      <p className="mt-1 text-sm text-gray-500">
        Add a new answer to your quiz question. You can have multiple answers be
        considered correct, making the question multiple-choice.
      </p>
    </div>

    <TextArea
      name="answer"
      className="mb-5"
      required
      label="Answer"
      defaultValue={props.initialData?.text}
      error={form.isTouched('text')
        ? form.errorMessage('text')
        : undefined
      }
      onInput={form.update.text}
    />

    <div className="flex items-center mb-5">
      <TextField
        name="order"
        className="mb-5 flex-grow"
        type="text"
        label="Order"
        defaultValue={props.initialData?.order === '0' ? undefined : props.initialData?.order}
        error={form.isTouched('order')
          ? form.errorMessage('order')
          : undefined
        }
        onInput={form.update.order}
      />
      <div className="flex-shrink pl-5">
        <Toggle
          label="Correct?"
          defaultValue={props.initialData?.correct}
          onChange={enabled => form.update.correct.directly(enabled)}
        />
      </div>
    </div>

    <div className="flex justify-between">
      {props.onDelete && <Button
        type="button"
        kind="danger"
        onClick={() => props.onDelete?.()}
      >
        Delete
      </Button>}
      <div className="flex flex-grow justify-end items-center">
        <Button
          type="button"
          kind="secondary"
          className="mr-2"
          onClick={e => props.onCancel()}
          disabled={props.submitting}
        >
          <XIcon className="w-4 h-4 mr-2" />
          Cancel
        </Button>

        <Button
          type="button"
          kind="primary"
          disabled={some(form.errors) || props.submitting}
          onClick={() => props.onSubmit(form.formData as NewQuizAnswerFormData)}
        >
          {props.updating ? 'Update' : 'Create'}
        </Button>
      </div>
    </div>
  </form>;
};
