import { withAuthenticationRequired } from '@auth0/auth0-react';
import { omit } from 'lodash';
import { ComponentType } from 'react';

/**
 *
 */
export interface AuthenticatedProps<T> {
  readonly component: ComponentType<T>;
}

/**
 *
 * @param props
 */
export const Authenticated = <T extends {}>(props: T & AuthenticatedProps<T>) => {
  const Component = withAuthenticationRequired(props.component);
  const restProps = omit(props, 'component') as unknown as T;

  return <Component {...restProps} />
};
