import { useApi } from '../hooks/use-api';
import { List, ListItem } from '../components/List';
import { Button } from '../components/Button';
import { CalendarIcon, CheckCircleIcon, ClipboardListIcon, PlusIcon } from '@heroicons/react/solid';
import { formatDate } from '../lib/format-date';
import { Serialized } from '../serialized';
import { useNavigate } from 'react-router-dom';
import { QuizWithQuestions } from 'quiz-builder-api/src/lib/models/quiz-with-questions';
import { Quiz as QuizData } from 'quiz-builder-api/src/lib/models/types';
import { ModalProps, Modal } from '../components/Modal';
import { NewQuizFormProps, NewQuizForm, NewQuizFormData } from '../forms/NewQuizForm';
import { useContext, useState } from 'react';
import { useFetcher } from '../hooks/use-fetcher';
import { useCallback } from 'use-memo-one';
import { AppContext } from '../App';

/**
 *
 * @param props
 */
export const Quizzes: React.FC = props => {
  const [ showNewQuizForm, setShowNewQuizForm ] = useState(false);

  const navigate = useNavigate();

  const { alert } = useContext(AppContext);

  const { value: quizzes, refetch } = useApi<Serialized<QuizWithQuestions>[]>('/quizzes?include[]=questions');

  const { fetcher: postQuiz } = useFetcher('/quizzes', {
    method: 'post',
  });

  /**
   *
   */
  const onSubmit = useCallback(async (data: NewQuizFormData) => {
    const response = await postQuiz({
      body: JSON.stringify(data),
    });

    const result = await response.json() as QuizData;

    if (response.ok) {
      alert({
        title: 'Successfully created!',
        message: `Your new quiz "${result.title}" was successfully created.`,
        icon: <CheckCircleIcon className="text-green-400" />,
      });
    } else {
      alert({
        title: 'Failed to create!',
        message: `Your new quiz "${result.title}" was not created.`,
        icon: <CheckCircleIcon className="text-green-400" />,
      });
    }

    refetch();
  }, [alert, postQuiz, refetch]);

  return <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-10">
    <div className="max-w-full mx-auto">
      <h1 className="text-4xl mb-10">Quizzes</h1>
      <List
        title="Your Quizzes"
        description="Create, edit, view, and share your quizzes."
        renderActions={() =>
          <Button kind="primary" onClick={() => setShowNewQuizForm(true)}>
            <PlusIcon className="w-4 mr-2" /> New Quiz
          </Button>
        }
      >
        {quizzes.map(quiz =>
          <ListItem
            id={quiz.id}
            title={quiz.title}
            onClick={() => navigate(`/dashboard/quizzes/${quiz.id}`)}
            attributes={[
              {icon: ClipboardListIcon, title: `${quiz.questions.length} questions`},
              {icon: CalendarIcon, title: `Created on ${formatDate(quiz.createdAt || '')}`}
            ]}
            labels={[
              ...(quiz.publishedAt ? [{color: 'bg-green-200 text-green-700', text: 'Published'}] : [])
            ]}
          />
        )}
      </List>
    </div>

    <NewQuizFormModal
      open={showNewQuizForm}
      onClose={() => setShowNewQuizForm(false)}
      onSubmit={onSubmit}
      onCancel={() => setShowNewQuizForm(false)}
    />
  </div>;
};

/**
 *
 * @param props
 */
 const NewQuizFormModal: React.FC<ModalProps & NewQuizFormProps> = props => {
  return <Modal {...props}>
    <NewQuizForm {...props} />
  </Modal>;
};
