import classNames from 'classnames';
import { omit } from 'lodash';
import { PropsWithChildren } from 'react';

/**
 *
 */
export type ButtonProps<T extends keyof JSX.IntrinsicElements = 'button'> = {
  readonly size?: keyof typeof sizes;
  readonly as?: T;
  readonly kind?: keyof typeof kinds;
} & JSX.IntrinsicElements[T]

const sizes = {
  xs: 'px-2.5 py-1.5 text-xs rounded',
  sm: 'px-3 py-2 text-sm leading-4 rounded-md',
  md: 'px-4 py-2 rounded-md',
  lg: 'px-4 py-2 text-base rounded-md',
  xl: 'px-6 py-3 text-base rounded-md'
};

const kinds = {
  primary: 'text-white bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500',
  secondary: 'text-slate-500 bg-gray-50 border-gray-200 hover:bg-gray-100 hover:border-gray-300 focus:ring-slate-300',
  success: 'text-white bg-green-600 hover:bg-green-700 focus:ring-green-500',
  danger: 'text-slate-500 hover:text-red-700 bg-gray-50 border-gray-200 hover:border-red-600 focus:ring-red-600',
};

const disabledKinds = {
  primary: 'disabled:bg-indigo-200 disabled:hover:bg-indigo-400',
  secondary: 'disabled:text-slate-400 disabled:border-gray-100 disabled:hover:bg-gray-50 disabled:hover:border-gray-50',
  success: 'disabled:bg-green-400 disabled:hover:bg-green400',
  danger: '',
};

/**
 *
 * @param props
 */
export const Button: React.FC<PropsWithChildren<ButtonProps>> = props => {
  const Element = props.as || 'button';
  const elementProps = omit(props, 'className');
  const sizeClasses = sizes[props.size || 'sm'];
  const kindClasses = kinds[props.kind || 'primary'];
  const disabledKindClasses = disabledKinds[props.kind || 'primary'];

  return <Element
    {...elementProps}

    className={classNames(
      props.className,
      sizeClasses,
      kindClasses,
      'inline-flex items-center border border-transparent font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2',
      {[disabledKindClasses]: props.disabled, 'focus:ring-0 cursor-default pointer-events-none': props.disabled},
    )}
  >
    {props.children}
  </Element>
};
