import { ExclamationCircleIcon } from '@heroicons/react/solid'
import classNames from 'classnames';
import { omit } from 'lodash';
import { ReactNode } from 'react';

/**
 *
 */
export interface TextAreaProps {
  readonly label?: ReactNode;
  readonly error?: string;
  readonly required?: boolean;
}

/**
 *
 * @param props
 * @returns
 */
export const TextArea: React.FC<TextAreaProps & JSX.IntrinsicElements['textarea']> = props => {
  return (
    <div className={props.className}>
      <div className="flex justify-between">
        <label htmlFor={props.name} className="block text-sm font-medium text-gray-700">
          {props.label}
        </label>
        {props.required !== true && <span className="text-sm text-gray-500">
          Optional
        </span>}
      </div>
      <div className="mt-1 relative rounded-md shadow-sm">
        <textarea
          className={classNames(
            "block w-full pr-10 focus:outline-none sm:text-sm rounded-md",
            {"border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500": !!props.error},
            {"focus:ring-indigo-500 focus:border-indigo-500 border-gray-300": !props.error}
          )}
          {...omit(props, 'className')}
        />
        {props.error && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          </div>
        }
      </div>
      {props.error &&
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {props.error}
        </p>
      }
    </div>
  )
}
