import { useContext } from 'react';
import { AppContext } from '../App';
import { Button } from '../components/Button';
import { useApi } from '../hooks/use-api';

/**
 *
 * @param props
 */
export const Profile: React.FC = props => {
  const { logout } = useContext(AppContext);
  const { value, error } = useApi('/users/me');

  return <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-10">
    <div className="max-w-full mx-auto">
      <h1 className="text-4xl mb-10">Profile</h1>
      <Button size="lg" kind="danger" onClick={() => logout()}>
        Log out
      </Button>
    </div>
  </div>;
};
