import React, { createContext, ReactNode, useCallback, useState } from 'react';
import { useMemoOne } from 'use-memo-one';
import { LogoutOptions, useAuth0, User } from '@auth0/auth0-react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Home } from './pages/Home';
import { Application } from './layouts/Application';
import { HomeIcon, PuzzleIcon } from '@heroicons/react/solid';
import { Quizzes } from './pages/Quizzes';
import { Authenticated } from './Authenticated';
import { Profile } from './pages/Profile';
import { Quiz } from './pages/Quiz';
import { Question } from './pages/Question';
import { useSuspense } from './hooks/use-suspense';
import { Alert } from './components/Alert';
import { Prompt, PromptProps } from './components/Prompt';
import { useApi } from './hooks/use-api';
import { User as AppUser } from 'quiz-builder-api/src/lib/models/types';
import { PublicQuiz } from './pages/PublicQuiz';


/**
 *
 */
export interface AppProps {

}

/**
 *
 */
export interface AppContext {
  readonly user?: User;
  readonly appUser?: AppUser;
  readonly accessToken?: string;

  /**
   *
   */
  readonly alert: (data: Alert) => void;

  /**
   *
   */
  readonly prompt: (data: Omit<PromptProps, 'onResponse' | 'show'>) => Promise<boolean>;

  /**
   *
   */
  readonly logout: (options?: LogoutOptions | undefined) => void;
}

/**
 *
 */
export interface Alert {
  readonly title: ReactNode;
  readonly message: ReactNode;
  readonly icon: ReactNode;
}

export const AppContext = createContext({} as AppContext);

/**
 *
 * @param props
 */
export const App: React.FC<AppProps> = props => {
  const { user, getAccessTokenSilently, logout } = useAuth0();

  const { value: accessToken } = useSuspense(
    'authenticate',
    async () => getAccessTokenSilently(),
    [getAccessTokenSilently]
  );

  const { value: appUser } = useApi<AppUser>('/users/me');

  const [ alerts, setAlerts ] = useState<Alert[]>([]);
  const [ promptProps, setPromptProps ] = useState<PromptProps | null>(null);

  /**
   *
   */
  const hideAlert = useCallback((alert: Alert) => {
    setAlerts(alerts => alerts.filter(element => element !== alert))
  }, []);

  /**
   *
   */
  const alert = useCallback((data: Alert) => {
    setAlerts(alerts => [...alerts, data]);

    setTimeout(() => hideAlert(data), 5000);
  }, [hideAlert]);

  /**
   *
   */
  const prompt = useCallback((data: Omit<PromptProps, 'onResponse' | 'show'>) =>
    new Promise<boolean>((resolve, reject) =>
      setPromptProps({
        ...data,
        show: true,
        onResponse: (result) => {
          resolve(result);
          setPromptProps(null);
        },
      })
    )
  , []);

  const appContext = useMemoOne(() => ({
    user,
    appUser,
    accessToken,
    alert,
    prompt,
    logout,
  }), [user, accessToken, alert, appUser]);

  return <AppContext.Provider value={appContext}>
    {promptProps &&
      <Prompt {...promptProps} />
    }
    {alerts.map((alert, i) =>
      <Alert
        key={i}
        title={alert.title}
        icon={alert.icon}
        show={true}
        onHide={() => hideAlert(alert)}
      >{alert.message}</Alert>
    )}
    <Routes>
      <Route
        path="/quizzes/:uuid"
        element={<PublicQuiz />}
      />

      <Route
        path="/dashboard"
        element={
          <Authenticated component={Application} navigation={[
            {name: 'Home', href: '/dashboard', icon: HomeIcon},
            {name: 'Quizzes', href: '/dashboard/quizzes', icon: PuzzleIcon},
          ]} />
        }
      >
        <Route index element={<Home />} />
        <Route path="quizzes">
          <Route index element={<Quizzes />} />

          <Route path=":quizId">
            <Route index element={<Quiz />} />
            <Route path="questions">
              <Route path=":questionId">
                <Route index element={<Question />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="profile" element={<Profile />} />
      </Route>

      <Route
        path="*"
        element={<Navigate to="/dashboard" replace />}
      />
    </Routes>
  </AppContext.Provider>
};
