/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from 'react';
import { useCallback, useMemo } from 'use-memo-one';

/**
 *
 */
class SuspenseTracker<T> {
  promise!: Promise<void>;

  state!: 'pending' | 'fulfilled' | 'rejected';

  value?: T;

  error?: unknown;

  constructor(readonly fetcher: () => Promise<T>) {
    this.fetcher = fetcher;

    this.run();
  }

  run() {
    this.state = 'pending';

    // eslint-disable-next-line no-async-promise-executor
    this.promise = new Promise<void>(async (resolve, reject) => {
      try {
        this.value = await this.fetcher();
        this.state = 'fulfilled';
        resolve();
      } catch (e) {
        this.error = e;
        this.state = 'rejected';
        reject();
      }
    });
  }
}

/**
 *
 */
export interface SuspenseResult<T> {
  readonly value: T;
  readonly error?: unknown;

  /**
   *
   */
  readonly refetch: () => void;
}

/**
 *
 */
export interface SuspenseContext {
  readonly trackers: Record<string, SuspenseTracker<unknown>>;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const SuspenseContext = createContext<SuspenseContext>({
  trackers: {},
});

/**
 *
 * @param key
 * @param fetcher
 */
export function useSuspense<T>(
  key: null,
  fetcher: () => Promise<T>,
  deps?: unknown[],
): SuspenseResult<null>

/**
 *
 * @param key
 * @param fetcher
 */
export function useSuspense<T>(
  key: string,
  fetcher: () => Promise<T>,
  deps?: unknown[],
): SuspenseResult<T>

/**
 *
 * @param key
 * @param fetcher
 */
 export function useSuspense<T>(
  key: string | null,
  fetcher: () => Promise<T>,
  deps?: unknown[],
): SuspenseResult<T | null>

/**
 *
 */
export function useSuspense<T>(
  key: string | null,
  fetcher: () => Promise<T>,
  deps: unknown[] = [],
): SuspenseResult<T | null> {
  const { tracker, refetch } = useTracker(key, fetcher);

  const [ firstTimeLoaded, setFirstTimeLoaded ] = useState(true);

  useEffect(
    () => { if (!firstTimeLoaded) refetch() },
    [refetch, ...deps]
  );

  useEffect(() => setFirstTimeLoaded(false), []);

  // The tracker is settled, return the result
  const { value, error } = tracker || { value: null };

  return useMemo(
    () => ({ value, error, refetch }),
    [value, error, refetch]
  ) as SuspenseResult<T>;
};

/**
 *
 */
const useTracker = <T>(key: string | null, fetcher: () => Promise<T>) => {
  const { trackers } = useContext(SuspenseContext);

  // If a promise is fulfilled or rejected, re-run the fetcher if the `deps`
  // have changed.
  const tracker = useMemo(() =>
    key === null
      ? null
      : (trackers[key] ||= new SuspenseTracker(fetcher))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  , [key, fetcher, trackers]);

  // If the tracker is now pending, it's a re-run
  if (tracker?.state === 'pending') {
    throw tracker.promise;
  }

  const [ , setRerun ] = useState(false);

  /**
   *
   */
  const refetch = useCallback(() => {
    console.log('REFETCH: ' + key)
    if (key === null) return;

    delete trackers[key];
    setRerun(true);
  }, [trackers, key]);

  return useMemo(() => ({ tracker, refetch }), [ tracker, refetch ]);
};
